import axios from 'axios';
import { Manga, Chapter } from '../types/manga';

const BASE_URL = process.env.NODE_ENV === 'production'
    ? '/.netlify/functions/manga-proxy'
    : 'https://api.mangadex.org';

const COVER_BASE_URL = process.env.NODE_ENV === 'production'
    ? '/.netlify/functions/manga-proxy/covers'
    : 'https://uploads.mangadex.org/covers';

// Create axios instance with proper error handling
const api = axios.create({
    baseURL: BASE_URL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

// Add request interceptor for better error messages
api.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

// Add response interceptor for better error handling
api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const status = error.response.status;
            const message = error.response.data?.message || 'Unknown error occurred';
            
            if (status === 429) {
                console.warn('Rate limit exceeded. Retrying...');
            } else if (status >= 500) {
                console.error(`Server error (${status}):`, message);
            } else if (status === 404) {
                console.error('Resource not found:', error.config.url);
            } else {
                console.error(`API error (${status}):`, message);
            }
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server:', error.message);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error setting up request:', error.message);
        }
        
        return Promise.reject(error);
    }
);

// Implement request interceptor for better error handling
api.interceptors.request.use((config) => {
    config.params = {
        ...config.params,
        includes: ['cover_art'],
    };
    return config;
});

// Retry function with exponential backoff
const retryWithBackoff = async <T>(
    fn: () => Promise<T>,
    retries = 3,
    baseDelay = 1000,
    maxDelay = 10000
): Promise<T> => {
    try {
        return await fn();
    } catch (error: any) {
        // Don't retry if we're out of retries or if it's a permanent error
        const status = error?.response?.status;
        const isRetryable = status >= 500 || status === 429 || !status;
        
        if (retries === 0 || !isRetryable) {
            throw error;
        }

        // Calculate delay with jitter to prevent thundering herd
        const jitter = Math.random() * 1000;
        const delay = Math.min(baseDelay + jitter, maxDelay);
        
        console.log(`Retrying API call after ${Math.round(delay)}ms. Retries left: ${retries}`);
        await new Promise(resolve => setTimeout(resolve, delay));
        
        return retryWithBackoff(fn, retries - 1, Math.min(baseDelay * 2, maxDelay), maxDelay);
    }
};

const processMangas = (items: any[]): Manga[] => {
    return items.map(item => ({
        id: item.id,
        title: item.attributes.title.en || Object.values(item.attributes.title)[0],
        description: item.attributes.description?.en || '',
        coverImage: `${COVER_BASE_URL}/${item.id}/${
            item.relationships.find((rel: any) => rel.type === 'cover_art')?.attributes?.fileName
        }`,
        status: item.attributes.status,
        year: item.attributes.year,
        tags: item.attributes.tags.map((tag: any) => tag.attributes.name.en),
        genres: item.attributes.tags
            .filter((tag: any) => tag.attributes.group === 'genre')
            .map((tag: any) => tag.attributes.name.en),
        rating: item.attributes.rating?.average || 0,
        updatedAt: item.attributes.updatedAt || new Date().toISOString(),
    }));
};

const searchManga = async (query: string): Promise<Manga[]> => {
    try {
        const response = await retryWithBackoff(() => 
            api.get('/manga', {
                params: {
                    title: query,
                    limit: 20,
                    offset: 0,
                    order: { relevance: 'desc' },
                },
            })
        );
        return processMangas(response.data.data);
    } catch (error) {
        console.error('Error searching manga:', error);
        throw new Error('Failed to load search results. Please try again later.');
    }
};

const getPopularManga = async (offset: number): Promise<Manga[]> => {
    const limit = 20;
    let retries = 3;
    
    while (retries > 0) {
        try {
            const response = await axios.get(`${BASE_URL}/manga`, {
                params: {
                    limit,
                    offset: offset * limit,
                    order: { rating: 'desc' },
                    includes: ['cover_art']
                }
            });
            
            return processMangas(response.data.data);
        } catch (error) {
            retries--;
            if (retries === 0) throw error;
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
    throw new Error('Failed to fetch popular manga after retries');
};

const getMangaDetails = async (mangaId: string): Promise<Manga> => {
    try {
        const response = await retryWithBackoff(() => 
            api.get(`/manga/${mangaId}`)
        );
        const [manga] = processMangas([response.data.data]);
        return manga;
    } catch (error) {
        console.error('Error getting manga details:', error);
        throw new Error('Failed to load manga details. Please try again later.');
    }
};

const getMangaChapters = async (mangaId: string): Promise<Chapter[]> => {
    let retries = 3;
    const chapters: Chapter[] = [];
    let totalChapters = 0;
    let currentOffset = 0;
    const limit = 100;

    while (retries > 0) {
        try {
            do {
                const response = await axios.get(`${BASE_URL}/manga/${mangaId}/feed`, {
                    params: {
                        limit,
                        offset: currentOffset,
                        order: { chapter: 'asc' },
                        translatedLanguage: ['en']
                    }
                });

                const newChapters = response.data.data;
                chapters.push(...newChapters);

                if (totalChapters === 0) {
                    totalChapters = response.data.total;
                }

                currentOffset += limit;

            } while (chapters.length < totalChapters);

            // Process and deduplicate chapters
            const chaptersMap = new Map<string, Chapter>();
            
            // Sort chapters by quality and date before processing
            chapters.sort((a: any, b: any) => {
                // First, compare by quality (high quality first)
                if (a.attributes.quality && !b.attributes.quality) return -1;
                if (!a.attributes.quality && b.attributes.quality) return 1;
                
                // If quality is the same, compare by date (newer first)
                return new Date(b.attributes.publishAt).getTime() - 
                       new Date(a.attributes.publishAt).getTime();
            });

            chapters.forEach((item: any) => {
                const chapterNum = item.attributes.chapter;
                if (!chapterNum) return;

                // Normalize the chapter number to handle different formats
                const normalizedChapterNum = parseFloat(chapterNum).toString();
                
                // Only add if we haven't seen this chapter number yet
                if (!chaptersMap.has(normalizedChapterNum)) {
                    const scanlationGroup = item.relationships?.find((rel: any) => 
                        rel.type === 'scanlation_group'
                    );

                    const chapter: Chapter = {
                        id: item.id,
                        chapter: chapterNum,
                        title: item.attributes.title || undefined,
                        pages: [],  // Initialize as empty array
                        publishAt: item.attributes.publishAt,
                        quality: item.attributes.quality,
                        groupName: scanlationGroup?.attributes?.name
                    };

                    // Only add chapters that have page count
                    if (item.attributes.pages && item.attributes.pages > 0) {
                        chaptersMap.set(normalizedChapterNum, chapter);
                    }
                }
            });

            // Convert map to array and sort by chapter number
            return Array.from(chaptersMap.values())
                .sort((a, b) => {
                    const aNum = parseFloat(a.chapter);
                    const bNum = parseFloat(b.chapter);
                    return isNaN(aNum) || isNaN(bNum) ? 0 : aNum - bNum;
                });

        } catch (error) {
            retries--;
            if (retries === 0) throw error;
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
    throw new Error('Failed to fetch manga chapters after retries');
};

const getMangaChapter = async (chapterId: string): Promise<Chapter> => {
    try {
        const response = await retryWithBackoff(() =>
            axios.get(`${BASE_URL}/chapter/${chapterId}`)
        );

        const item = response.data.data;
        const scanlationGroup = item.relationships?.find((rel: any) => 
            rel.type === 'scanlation_group'
        );

        const chapter: Chapter = {
            id: item.id,
            chapter: item.attributes.chapter,
            title: item.attributes.title || undefined,
            pages: [],  // Initialize empty array, will be populated by getChapterPages
            publishAt: item.attributes.publishAt,
            quality: item.attributes.quality,
            groupName: scanlationGroup?.attributes?.name
        };

        return chapter;
    } catch (error) {
        console.error('Error fetching chapter:', error);
        throw error;
    }
};

const getChapterPages = async (chapterId: string): Promise<string[]> => {
    try {
        const response = await retryWithBackoff(() =>
            api.get(`/at-home/server/${chapterId}`)
        );
        
        const { baseUrl, chapter } = response.data;
        
        // Try to use data-saver images first for faster loading
        const images = chapter.dataSaver?.length > 0 ? 
            chapter.dataSaver.map((filename: string) => 
                `${baseUrl}/data-saver/${chapter.hash}/${filename}`
            ) :
            chapter.data.map((filename: string) => 
                `${baseUrl}/data/${chapter.hash}/${filename}`
            );

        return images;
    } catch (error) {
        console.error('Error getting chapter pages:', error);
        throw new Error('Failed to load chapter pages. Please try again later.');
    }
};

export const mangaApi = {
    searchManga,
    getPopularManga,
    getMangaDetails,
    getMangaChapters,
    getChapterPages,
    getMangaChapter,
    getManga: getMangaDetails, // alias for getMangaDetails
};
