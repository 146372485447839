import React, { useEffect, useState, useCallback } from 'react';
import { Box, Container, Heading, SimpleGrid, Skeleton, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { mangaApi } from '../services/mangaApi';
import { userDataService } from '../services/userDataService';
import { Manga } from '../types/manga';
import MangaCard from '../components/MangaCard';
import SearchBar from '../components/SearchBar';
import FilterDrawer from '../components/FilterDrawer';
import debounce from 'lodash/debounce';
import SEO from '../components/SEO';

const GENRES = [
    'Action', 'Adventure', 'Comedy', 'Drama', 'Fantasy', 'Horror', 
    'Mystery', 'Romance', 'Sci-Fi', 'Slice of Life', 'Sports', 'Supernatural'
];

const HomePage: React.FC = () => {
    const [popularManga, setPopularManga] = useState<Manga[]>([]);
    const [recentlyViewed, setRecentlyViewed] = useState<Manga[]>([]);
    const [searchResults, setSearchResults] = useState<Manga[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [sortBy, setSortBy] = useState('popular');
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    
    const { isOpen, onOpen, onClose } = useDisclosure();
    const bgColor = useColorModeValue('white', 'gray.800');

    const filterMangaByGenres = (manga: Manga[]): Manga[] => {
        if (selectedGenres.length === 0) return manga;
        return manga.filter(m => 
            selectedGenres.every(genre => m.genres.includes(genre))
        );
    };

    const loadMoreManga = useCallback(async () => {
        if (isLoadingMore || !hasMore || searchQuery) return;
        
        try {
            setIsLoadingMore(true);
            const newManga = await mangaApi.getPopularManga(page);
            if (newManga.length === 0) {
                setHasMore(false);
            } else {
                const filteredManga = filterMangaByGenres(newManga);
                setPopularManga(prev => [...prev, ...filteredManga]);
                setPage(prev => prev + 1);
            }
        } catch (error) {
            console.error('Error loading more manga:', error);
            setError('Failed to load more manga. Please try again later.');
        } finally {
            setIsLoadingMore(false);
        }
    }, [isLoadingMore, hasMore, page, searchQuery]);

    // Load initial data
    useEffect(() => {
        const loadInitialData = async () => {
            try {
                setIsLoading(true);
                const [popular, recent] = await Promise.all([
                    mangaApi.getPopularManga(1),
                    userDataService.getRecentlyViewed()
                ]);
                const filteredPopular = filterMangaByGenres(popular);
                setPopularManga(filteredPopular);
                setRecentlyViewed(recent);
                setPage(2);
            } catch (error) {
                console.error('Error loading initial data:', error);
                setError('Failed to load manga. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        loadInitialData();
    }, [selectedGenres]); // Re-fetch when genres change

    const debouncedSearch = useCallback(
        debounce(async (query: string) => {
            if (!query.trim()) {
                setSearchResults([]);
                return;
            }

            try {
                setIsLoading(true);
                const results = await mangaApi.searchManga(query);
                
                // Apply genre filter
                let filteredResults = filterMangaByGenres(results);

                // Apply sorting
                filteredResults = [...filteredResults].sort((a, b) => {
                    switch (sortBy) {
                        case 'title':
                            return a.title.localeCompare(b.title);
                        case 'latest':
                            return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
                        default:
                            return 0;
                    }
                });

                setSearchResults(filteredResults);
            } catch (error) {
                console.error('Error searching manga:', error);
                setError('Search failed. Please try again.');
            } finally {
                setIsLoading(false);
            }
        }, 500),
        [selectedGenres, sortBy]
    );

    useEffect(() => {
        if (searchQuery) {
            debouncedSearch(searchQuery);
        } else {
            setSearchResults([]);
        }
        return () => {
            debouncedSearch.cancel();
        };
    }, [searchQuery, debouncedSearch]);

    useEffect(() => {
        const filteredManga = filterMangaByGenres(popularManga);
        setPopularManga(filteredManga);
    }, [selectedGenres, popularManga, filterMangaByGenres]);

    useEffect(() => {
        if (searchQuery) {
            const filtered = filterMangaByGenres(searchResults);
            setSearchResults(filtered);
        } else {
            const filtered = filterMangaByGenres(popularManga);
            setPopularManga(filtered);
        }
    }, [searchQuery, selectedGenres, searchResults, popularManga, filterMangaByGenres]);

    const handleScroll = useCallback(() => {
        if (window.innerHeight + document.documentElement.scrollTop
            === document.documentElement.offsetHeight) {
            loadMoreManga();
        }
    }, [loadMoreManga]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    if (isLoading && !popularManga.length) {
        return (
            <Box minH="100vh" bg={bgColor}>
                <Container maxW="container.xl" py={8}>
                    <SimpleGrid columns={{ base: 2, sm: 3, md: 4, lg: 5, xl: 6 }} spacing={4}>
                        {[...Array(12)].map((_, i) => (
                            <Skeleton key={i} height="300px" />
                        ))}
                    </SimpleGrid>
                </Container>
            </Box>
        );
    }

    return (
        <Box minH="100vh" bg={bgColor}>
            <SEO 
                title="1331 Manga - Discover and Read Manga Online"
                description="Browse our extensive collection of manga. Find popular titles, latest updates, and your favorite series all in one place."
            />
            <Container maxW="container.xl" py={8}>
                <SearchBar
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onFilterClick={onOpen}
                />

                <FilterDrawer
                    isOpen={isOpen}
                    onClose={onClose}
                    genres={GENRES}
                    selectedGenres={selectedGenres}
                    setSelectedGenres={setSelectedGenres}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                />

                {error && (
                    <Box mb={4} p={4} bg="red.100" color="red.900" borderRadius="md">
                        {error}
                    </Box>
                )}

                {/* Recently Viewed Section */}
                {recentlyViewed.length > 0 && (
                    <Box mb={8}>
                        <Heading size="lg" mb={4}>Recently Viewed</Heading>
                        <SimpleGrid columns={{ base: 2, sm: 3, md: 4, lg: 5, xl: 6 }} spacing={4}>
                            {recentlyViewed.map(manga => (
                                <MangaCard key={manga.id} manga={manga} />
                            ))}
                        </SimpleGrid>
                    </Box>
                )}

                {/* Popular/Search Results Section */}
                <Box>
                    <Heading size="lg" mb={4}>
                        {searchQuery ? 'Search Results' : 'Popular Manga'}
                    </Heading>
                    <SimpleGrid columns={{ base: 2, sm: 3, md: 4, lg: 5, xl: 6 }} spacing={4}>
                        {(searchQuery ? searchResults : popularManga).map(manga => (
                            <MangaCard key={manga.id} manga={manga} />
                        ))}
                    </SimpleGrid>
                    {!searchQuery && (
                        <Box textAlign="center" py={4}>
                            {isLoadingMore && <Skeleton height="20px" />}
                            {!hasMore && <Text>No more manga to load</Text>}
                        </Box>
                    )}
                </Box>
            </Container>
        </Box>
    );
};

export default HomePage;
